define("discourse/plugins/chat/discourse/components/chat-notices", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-notices">
    <ChatRetentionReminder @channel={{@channel}} />
  
    {{#each this.noticesForChannel as |notice|}}
      <ChatNotice @notice={{notice}} @channel={{@channel}} />
    {{/each}}
  </div>
  */
  {
    "id": "UNJhKnD9",
    "block": "[[[10,0],[14,0,\"chat-notices\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"noticesForChannel\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@notice\",\"@channel\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[2]],null],[13]],[\"@channel\",\"notice\"],false,[\"chat-retention-reminder\",\"each\",\"-track-array\",\"chat-notice\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-notices.hbs",
    "isStrictMode": false
  });
  let ChatNotices = _exports.default = (_dec = (0, _service.inject)("chat-channel-notices-manager"), (_class = class ChatNotices extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "noticesManager", _descriptor, this);
    }
    get noticesForChannel() {
      return this.noticesManager.notices.filter(notice => notice.channelId === this.args.channel.id);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "noticesManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatNotices);
});