define("discourse/plugins/chat/discourse/components/chat-drawer/channel", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ChatDrawer::Header @toggleExpand={{@drawerActions.toggleExpand}}>
    <ChatDrawer::Header::LeftActions />
  
    <ChatDrawer::Header::ChannelTitle
      @channel={{this.chat.activeChannel}}
      @drawerActions={{@drawerActions}}
    />
  
    <ChatDrawer::Header::RightActions @drawerActions={{@drawerActions}} />
  </ChatDrawer::Header>
  
  {{#if this.chatStateManager.isDrawerExpanded}}
    <div
      class="chat-drawer-content"
      {{did-insert this.fetchChannel}}
      {{did-update this.fetchChannel @params.channelId}}
    >
      {{#if this.chat.activeChannel}}
        {{#each (array this.chat.activeChannel) as |channel|}}
          {{#if channel}}
            <ChatChannel
              @targetMessageId={{readonly @params.messageId}}
              @channel={{channel}}
            />
          {{/if}}
        {{/each}}
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "V50cdG/E",
    "block": "[[[8,[39,0],null,[[\"@toggleExpand\"],[[30,1,[\"toggleExpand\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@channel\",\"@drawerActions\"],[[30,0,[\"chat\",\"activeChannel\"]],[30,1]]],null],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@drawerActions\"],[[30,1]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"  \"],[11,0],[24,0,\"chat-drawer-content\"],[4,[38,5],[[30,0,[\"fetchChannel\"]]],null],[4,[38,6],[[30,0,[\"fetchChannel\"]],[30,2,[\"channelId\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"chat\",\"activeChannel\"]],[[[42,[28,[37,8],[[28,[37,8],[[28,[37,9],[[30,0,[\"chat\",\"activeChannel\"]]],null]],null]],null],null,[[[41,[30,3],[[[1,\"          \"],[8,[39,10],null,[[\"@targetMessageId\",\"@channel\"],[[28,[37,11],[[30,2,[\"messageId\"]]],null],[30,3]]],null],[1,\"\\n\"]],[]],null]],[3]],null]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@drawerActions\",\"@params\",\"channel\"],false,[\"chat-drawer/header\",\"chat-drawer/header/left-actions\",\"chat-drawer/header/channel-title\",\"chat-drawer/header/right-actions\",\"if\",\"did-insert\",\"did-update\",\"each\",\"-track-array\",\"array\",\"chat-channel\",\"readonly\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-drawer/channel.hbs",
    "isStrictMode": false
  });
  let ChatDrawerChannel = _exports.default = (_class = class ChatDrawerChannel extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
      _initializerDefineProperty(this, "chat", _descriptor2, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor3, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor4, this);
    }
    fetchChannel() {
      if (!this.args.params?.channelId) {
        return;
      }
      return this.chatChannelsManager.find(this.args.params.channelId).then(channel => {
        this.chat.activeChannel = channel;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchChannel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatDrawerChannel);
});